<template>
  <div class="wrapper">
    <!-- 页面头部部分 -->
    <div class="sljr-header">
      <!-- 水平一级菜单 -->
      <div class="header-center">
        <img src="@/assets/images/header-logo.png" class="logo" alt="logo" />
        <el-menu
          :default-active="toIndex()"
          mode="horizontal"
          @select="handleSelect"
        >
          <template v-for="item in items">
            <el-menu-item
              v-if="!item.subMenu"
              :index="item.path"
              :key="item.path"
            >
              <template slot="title">{{ item.title }}</template>
            </el-menu-item>
            <el-submenu v-if="item.subMenu" :index="item.path" :key="item.path">
              <template slot="title">{{ item.title }}</template>
              <template v-for="(subItem, i) in item.subMenu">
                <el-menu-item :index="subItem.path" :key="i">{{
                  subItem.title
                }}</el-menu-item>
              </template>
            </el-submenu>
          </template>
        </el-menu>
      </div>
    </div>

    <!-- 页面左侧二级菜单栏，和主体内容区域部分 -->
    <el-main class="sljr-main">
      <router-view></router-view>
    </el-main>

    <!-- 页面底部 -->
    <el-footer class="sljr-footer" height="inherit">
      <div class="footer-content">
        <div class="name">
          广东同汇科技股份有限公司 ·同汇集团相关网站·品牌认证
        </div>
        <div class="copy">
          2020-{{ new Date().getFullYear() }} 广东同汇科技股份有限公司 版权所有
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
            >粤ICP备2021084657号-1</a
          >
        </div>
      </div>
    </el-footer>
  </div>
</template>
<script>
export default {
  data () {
    return {
      nowKey: 'index',
      items: [
        { path: 'aboutUs', title: '关于同汇' },
        { path: 'businessArea', title: '业务领域', },
        { path: 'investorRelations', title: '投资者关系' },
        { path: 'news', title: '新闻中心' },
        { path: 'joinUs', title: '加入同汇' },
        { path: 'contactUs', title: '联系我们' },
      ]
    }
  },
  methods: {
    // 根据路径绑定到对应的一级菜单，防止页面刷新重新跳回第一个
    toIndex () {
      return this.$route.path.split('/')[1];
    },
    // 切换菜单栏
    handleSelect (key, keyPath) {
      console.log('layout--', key, keyPath)
      this.nowKey = key;
      this.$router.push('/' + key);
    },
  },

}
</script>
<style lang="scss">
body {
  margin: 0;
}
.wrapper {
  width: 100%;
  height: 100%;
}
.sljr-footer {
  color: #2b2b2b;
  font-size: 15px;
  border-top: 3px solid #c0976b;
  text-align: center;
  .footer-content {
    padding-top: 28px;
    padding-bottom: 60px;
    .copy {
      opacity: 0.5;
      font-size: 12px;
      margin-top: 20px;
      a {
        color: #2b2b2b;
      }
    }
  }
}

/* --------------- 水平一级菜单栏的样式--------------------- */
.sljr-header {
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 90px;
  font-size: 18px;
  background: #fff;
  z-index: 2021;
}
.header-center {
  height: 100%;
  width: 1200px;
  margin: 0 auto;
}
.logo {
  width: 114px;
  height: 52px;
  margin-top: 19px;
}
.el-menu.el-menu--horizontal {
  border: none;
  display: inline-block;
  float: right;
  height: 90px;
  background: #fff;
}
.el-menu--horizontal > .el-menu-item,
.el-menu--horizontal > .el-submenu .el-submenu__title {
  border-bottom: none;
  color: #2b2b2b;
  height: 90px;
  line-height: 90px;
  font-size: 18px;
  padding: 0 30px;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus {
  background: #b7a16f;
  color: #fff;
  font-weight: bold;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: #b7a16f;
  color: #fff;
}
.el-menu--horizontal > .el-menu-item.is-active,
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: none;
  color: #fff;
  font-weight: bold;
  background: #b7a16f;
}
.el-menu--horizontal > .el-submenu:focus .el-submenu__title {
  color: #2b2b2b;
}
.el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
  display: none;
}

.el-menu--popup-bottom-start {
  margin-top: 0;
}
.el-menu--horizontal .el-menu .el-menu-item {
  height: 32px;
  line-height: 32px;
  color: #666;
  font-size: 16px;
}
.el-menu--popup {
  padding: 0;
  border-radius: 0;
  width: 132px;
  min-width: initial;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 6px solid $main-color;
  .el-menu-item:not(.is-disabled):hover {
    background: #fff;
    color: $main-color;
  }
  .el-menu-item.is-active {
    color: $main-color !important;
  }
}

// --------------------主内容----------------------
.sljr-main {
  padding: 90px 0 0 0;
  .head-picture {
    position: relative;
    .cover {
      width: 100%;
    }
    .mask {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .title {
      width: 1200px;
      margin: 0 auto;
      position: absolute;
      top: 90px;
      left: calc((100vw - 1200px + 120px) / 2);
      //   transform: translateY(-50%);
      font-weight: 400;
      color: #323232;
      font-size: 50px;
    }
  }
  .sljr-render {
    padding: 50px 60px;
    background: rgba(255, 255, 255, 0.7);
  }
  .sljr-container {
    width: 1200px;
    box-sizing: border-box;
    margin: -180px auto 0 auto;
    position: relative;
    .el-main {
      padding: 0 0 0 34px;
    }
  }
}
</style>